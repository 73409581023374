/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { HDivider } from "./amenities";
import Checkbox from "./customCheckBox";
import Selection from "../utils/account/select";
import { PrimaryButtons, SecondaryButtons } from "./buttons";
import {
  bathsList,
  bedsList,
  searchDate,
  squareFeet,
  distressedLevel,
  PreforeclosureStatusType,
} from "../utils/selectionData";
import DatePicker from "../DatePicker";
import { PrimaryInput } from "./inputFields";
import { formattedDate, todaysDate } from "./currentDateFormated";
import useAPI from "./UseApi";
import MultiSelectDropdown from "./multiSelect";

const FilterPage = ({
  handleOpen,
  open,
  status = true,
  hasSearchDate = true,
  mls = true,
  price = true,
  title,
  expiredStatuses,
  setDistressedLevel,
  setPrefotype,
  filter,
  filterArray,
  params,
  isOld = false,
  lastRemovedFilter,
}) => {
  const filteredArray = filterArray.filter((obj) => {
    // Check if any value in the object is an empty string, null, or "Any"
    return !Object.values(obj).some(
      (value) =>
        value === "" || value === null || value === "Any" || value === undefined
    );
  });
  console.log(lastRemovedFilter);
  const { get } = useAPI();
  const [expiredStatus, setExpiredStatus] = useState(true);
  const [expiredStatusLocal, setExpiredStatusLocal] = useState(true);

  const [pendingStatus, setPendingStatus] = useState(!true);
  const [pendingStatusLocal, setPendingStatusLocal] = useState(!true);

  const [withdrawnStatus, setWithdrawnStatus] = useState(true);
  const [withdrawnStatusLocal, setWithdrawnStatusLocal] = useState(true);

  const [unavailableStatus, setUnavailableStatus] = useState(false);
  const [unavailableStatusLocal, setUnavailableStatusLocal] = useState(false);

  const [offMarketStatus, setOffMarketStatus] = useState(false);
  const [offMarketStatusLocal, setOffMarketStatusLocal] = useState(false);

  const [canceledStatus, setCanceledStatus] = useState(!false);
  const [canceledStatusLocal, setCanceledStatusLocal] = useState(!false);

  const [releasedStatus, setReleasedStatus] = useState(false);
  const [releasedStatusLocal, setReleasedStatusLocal] = useState(false);

  const [activeStatus, setActiveStatus] = useState(false);
  const [activeStatusLocal, setActiveStatusLocal] = useState(false);

  const [rentedStatus, setRentedStatus] = useState(false);
  const [rentedStatusLocal, setRentedStatusLocal] = useState(false);

  const [soldStatus, setSoldStatus] = useState(false);
  const [soldStatusLocal, setSoldStatusLocal] = useState(false);

  const [searchDateType, setSearchDateType] = useState(
    title === "Expired Filters" || title === "Old Expired Filters"
      ? "Lead Delivery Date"
      : ""
  );
  const [searchDateTypeLocal, setSearchDateTypeLocal] = useState(
    title === "Expired Filters" || title === "Old Expired Filters"
      ? "Lead Delivery Date"
      : ""
  );

  const [searchDateState, setSearchDateState] = useState();
  const [searchDateStateLocal, setSearchDateStateLocal] = useState();

  const [from_date, setfrom_date] = useState(
    isOld ? "08/30/2013" : formattedDate
  );
  const [from_dateLocal, setfrom_dateLocal] = useState(
    isOld ? "08/30/2013" : formattedDate
  );

  const [to_date, setto_date] = useState(todaysDate);
  const [to_dateLocal, setto_dateLocal] = useState(todaysDate);

  const [mlsREcord, setMlsRecord] = useState("");
  const [mlsREcordLocal, setMlsRecordLocal] = useState("");

  const [mlsRecordState, setMlsRecordState] = useState("");
  const [mlsRecordStateLocal, setMlsRecordStateLocal] = useState("");

  const [beds, setbeds] = useState("");
  const [bedsLocal, setbedsLocal] = useState("");

  const [bedState, setBedState] = useState("");
  const [bedStateLocal, setBedStateLocal] = useState("");

  const [baths, setbaths] = useState("");
  const [bathsLocal, setbathsLocal] = useState("");

  const [bathState, setbathState] = useState("");
  const [bathStateLocal, setbathStateLocal] = useState("");

  const [min_price, setmin_price] = useState("");
  const [min_priceLocal, setmin_priceLocal] = useState("");

  const [max_price, setmax_price] = useState("");
  const [max_priceLocal, setmax_priceLocal] = useState("");

  const [sq_ft, setsq_ft] = useState("");
  const [sq_ftLocal, setsq_ftLocal] = useState("");

  const [sq_ftState, setsq_ftState] = useState("");
  const [sq_ftStateLocal, setsq_ftStateLocal] = useState("");

  const [postalCode, setPostslCode] = useState("");
  const [postalCodeLocal, setPostslCodeLocal] = useState("");

  const [distressed_level, setDistressed_level] = useState([
    "0 - Not Distressed",
    "1 - Somewhat Distressed",
    "2 - Fairly Distressed",
    "3 - Highly Distressed",
    "4 - Extremely Distressed",
  ]);
  const [distressed_levelLocal, setDistressed_levelLocal] = useState([
    "0 - Not Distressed",
    "1 - Somewhat Distressed",
    "2 - Fairly Distressed",
    "3 - Highly Distressed",
    "4 - Extremely Distressed",
  ]);

  const [distressedLevelState, setDistressedLevelState] = useState([
    {
      name: "0 - Not Distressed",
    },
    {
      name: "1 - Somewhat Distressed",
    },
    {
      name: "2 - Fairly Distressed",
    },
    {
      name: "3 - Highly Distressed",
    },
    {
      name: "4 - Extremely Distressed",
    },
  ]);
  const [distressedLevelStateLocal, setDistressedLevelStateLocal] = useState([
    {
      name: "0 - Not Distressed",
    },
    {
      name: "1 - Somewhat Distressed",
    },
    {
      name: "2 - Fairly Distressed",
    },
    {
      name: "3 - Highly Distressed",
    },
    {
      name: "4 - Extremely Distressed",
    },
  ]);

  const [preFoState, setPreFoState] = useState("");
  const [preFoStateLocal, setPreFoStateLocal] = useState("");

  const [preFoStateState, setPreFoStateState] = useState([]);
  const [preFoStateStateLocal, setPreFoStateStateLocal] = useState([]);

  const [showActives, setShowActives] = useState(true);
  const [showActivesLocal, setShowActivesLocal] = useState(true);

  const [tags, setTags] = useState("");
  const [tagsLocal, setTagsLocal] = useState("");

  const [statuses, setStatuses] = useState([]);
  const [statusesLocal, setStatusesLocal] = useState([]);

  useEffect(() => {
    if (open) {
      setExpiredStatusLocal(expiredStatus);
      setPendingStatusLocal(pendingStatus);
      setWithdrawnStatusLocal(withdrawnStatus);
      setUnavailableStatusLocal(unavailableStatus);
      setOffMarketStatusLocal(offMarketStatus);
      setCanceledStatusLocal(canceledStatus);
      setReleasedStatusLocal(releasedStatus);
      setActiveStatusLocal(activeStatus);
      setRentedStatusLocal(rentedStatus);
      setSoldStatusLocal(soldStatus);
      setSearchDateTypeLocal(searchDateType);
      setSearchDateStateLocal(searchDateState);
      setfrom_dateLocal(from_date);
      setto_dateLocal(to_date);
      setMlsRecordLocal(mlsREcord);
      setMlsRecordStateLocal(mlsRecordState);
      setbedsLocal(beds);
      setBedStateLocal(bedState);
      setbathsLocal(baths);
      setbathStateLocal(bathState);
      setmin_priceLocal(min_price);
      setmax_priceLocal(max_price);
      setsq_ftLocal(sq_ft);
      setsq_ftStateLocal(sq_ftState);
      setPostslCodeLocal(postalCode);
      setDistressed_levelLocal(distressed_level);
      setDistressedLevelStateLocal(distressedLevelState);
      setPreFoStateLocal(preFoState);
      setPreFoStateStateLocal(preFoStateState);
      setShowActivesLocal(showActives);
      setTagsLocal(tags);
      setStatusesLocal(statuses);
    }
    // eslint-disable-next-line
  }, [open]);

  console.log({ distressedLevelStateLocal });
  console.log({ distressed_levelLocal });

  useEffect(() => {
    if (!open) {
      const resetField = (fieldType) => {
        switch (fieldType) {
          case "Search Type":
            setSearchDateType("");
            setSearchDateState("");
            setSearchDateStateLocal("");
            setSearchDateTypeLocal("");
            break;
          case "searchDateState":
            setSearchDateStateLocal("");
            break;
          case "from_date":
            setfrom_dateLocal("");
            break;
          case "to_date":
            setto_dateLocal("");
            break;
          case "mlsRecord":
            setMlsRecordLocal("");
            break;
          case "MLS":
            setMlsRecord("");
            setMlsRecordState("");
            setMlsRecordLocal("");
            setMlsRecordStateLocal("");
            break;
          case "Beds":
            setbeds("");
            setBedState("");
            setbedsLocal("");
            setBedStateLocal("");
            break;

          case "Baths":
            setbathState("");
            setbathStateLocal("");
            setbaths("");
            setbathsLocal("");
            break;

          case "Min Price":
            setmin_price("");
            break;
          case "Max Price":
            setmax_price("");
            break;
          case "Sq Ft":
            setsq_ft("");
            setsq_ftState("");

            setsq_ftLocal("");
            setsq_ftStateLocal("");

            break;

          case "Postal Codes":
            setPostslCode("");
            setPostslCodeLocal("");
            break;
          case "distressed_level":
            setDistressed_levelLocal("");
            setDistressedLevelStateLocal("");
            break;
          case "preFoState":
            setPreFoStateLocal("");
            break;
          case "preFoStateState":
            setPreFoStateStateLocal("");
            break;
          case "showActives":
            setShowActivesLocal("");
            break;
          case "Tags":
            setTags("");
            setTagsLocal("");

            break;

          //statuses

          case "Expired":
            setExpiredStatus("");
            setExpiredStatusLocal("");
            break;

          case "Withdrawn":
            setWithdrawnStatus("");
            setWithdrawnStatusLocal("");
            break;

          case "Canceled":
            setCanceledStatus("");
            setCanceledStatusLocal("");
            break;

          case "Active":
            setActiveStatus("");
            setActiveStatusLocal("");
            setShowActives("");
            setActiveStatusLocal("");
            break;

          case "Sold":
            setSoldStatus("");
            setSoldStatusLocal("");
            break;

          case "Pending":
            setPendingStatus("");
            setPendingStatusLocal("");
            break;

          case "Off Market":
            setOffMarketStatus("");
            setOffMarketStatusLocal("");
            break;

          case "Released":
            setReleasedStatus("");
            setReleasedStatusLocal("");
            break;

          case "Rented":
            setRentedStatus("");
            setRentedStatusLocal("");
            break;

          case "Unavailable":
            setUnavailableStatus("");
            setUnavailableStatusLocal("");
            break;

          default:
            console.warn(`No matching field found for type: ${fieldType}`);
        }
      };

      // Example usage
      resetField(`${lastRemovedFilter}`);
    }
    // eslint-disable-next-line
  }, [lastRemovedFilter]);
  //const [showActivesState, setShowActivesState] = useState("");
  const [mlsList, setMlsList] = useState([
    { state: "All", name: "All", customer_mls_id: NaN },
  ]);

  const addFilter = () => {
    console.log("triggered");
    setExpiredStatus(expiredStatusLocal);
    setPendingStatus(pendingStatusLocal);
    setWithdrawnStatus(withdrawnStatusLocal);
    setUnavailableStatus(unavailableStatusLocal);
    setOffMarketStatus(offMarketStatusLocal);
    setCanceledStatus(canceledStatusLocal);
    setReleasedStatus(releasedStatusLocal);
    setActiveStatus(activeStatusLocal);
    setRentedStatus(rentedStatusLocal);
    setSoldStatus(soldStatusLocal);
    setSearchDateType(searchDateTypeLocal);
    setSearchDateState(searchDateStateLocal);
    setfrom_date(from_dateLocal);
    setto_date(to_dateLocal);
    setMlsRecord(mlsREcordLocal);
    setMlsRecordState(mlsRecordStateLocal);
    setbeds(bedsLocal);
    setBedState(bedStateLocal);
    setbaths(bathsLocal);
    setbathState(bathStateLocal);
    setmin_price(min_priceLocal);
    setmax_price(max_priceLocal);
    setsq_ft(sq_ftLocal);
    setsq_ftState(sq_ftStateLocal);
    setPostslCode(postalCodeLocal);
    setDistressed_level(distressed_levelLocal);
    setDistressedLevelState(distressedLevelStateLocal);
    setPreFoState(preFoStateLocal);
    setPreFoStateState(preFoStateStateLocal);
    setShowActives(showActivesLocal);
    setTags(tagsLocal);

    let selectedStatus = [];
    if (expiredStatusLocal) selectedStatus.push("X");

    if (pendingStatusLocal) selectedStatus.push("P");

    if (withdrawnStatusLocal) selectedStatus.push("W");

    if (unavailableStatusLocal) selectedStatus.push("N");

    if (offMarketStatusLocal) selectedStatus.push("O");

    if (canceledStatusLocal) selectedStatus.push("C");

    if (releasedStatusLocal) selectedStatus.push("R");

    if (activeStatusLocal) selectedStatus.push("A");

    if (rentedStatusLocal) selectedStatus.push("T");

    if (soldStatusLocal) selectedStatus.push("S");
    setStatusesLocal(selectedStatus);
    setTimeout(() => {
      setFIlter();
      if (expiredStatuses && status === true) expiredStatuses(selectedStatus);

      if (setDistressedLevel)
        setDistressedLevel(
          distressed_level[0] === "Show all " ? "" : distressed_levelLocal
        );

      if (setPrefotype)
        setPrefotype(preFoState[0] === "Show all " ? "" : preFoStateLocal);
    }, 200);
  };

  // eslint-disable-next-line

  useEffect(() => {
    addFilter();
  }, []);

  const handleDistressed = (levels = []) => {
    const distressedLevel = levels;
    setDistressed_levelLocal(distressedLevel);
  };

  const handleShowActives = (state) => {
    setShowActivesLocal(state);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get("/api/core/account/mls");
        setMlsList([...mlsList, ...result]);
      } catch {}
    };
    fetchData();

    window.addEventListener("keydown", handleKeyPress);

    // Removing the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPress = (event) => {
    // For example, checking if the pressed key is Enter

    if (open && event.key === "Enter") {
      event.preventDefault();
      setFIlter();
    }
  };

  const queryParams = [];
  useEffect(() => {
    let selectedStatus = [];
    if (expiredStatusLocal) selectedStatus.push("X");

    if (pendingStatusLocal) selectedStatus.push("P");

    if (withdrawnStatusLocal) selectedStatus.push("W");

    if (unavailableStatusLocal) selectedStatus.push("N");

    if (offMarketStatusLocal) selectedStatus.push("O");

    if (canceledStatusLocal) selectedStatus.push("C");

    if (releasedStatusLocal) selectedStatus.push("R");

    if (activeStatusLocal) selectedStatus.push("A");

    if (rentedStatusLocal) selectedStatus.push("T");

    if (soldStatusLocal) selectedStatus.push("S");
    setStatuses(selectedStatus);

    // eslint-disable-next-line
  }, [
    expiredStatusLocal,
    pendingStatusLocal,
    withdrawnStatusLocal,
    unavailableStatusLocal,
    offMarketStatusLocal,
    canceledStatusLocal,
    releasedStatusLocal,
    activeStatusLocal,
    rentedStatusLocal,
    soldStatusLocal,
  ]);

  // Iterate through all state variables
  const stateVariables = {
    date_search_type:
      searchDateTypeLocal === "Lead Delivery Date"
        ? "file_upload_date"
        : searchDateTypeLocal === "Original Status Date"
        ? "listing_status_date"
        : "",

    to_date: to_dateLocal,
    from_date: from_dateLocal,
    mls: mlsList.find((obj) => obj.name === mlsREcordLocal)?.customer_mls_id,
    beds: bedsLocal,
    baths: bathsLocal,
    min_price: min_priceLocal,
    max_price: max_priceLocal,
    sq_ft: sq_ftLocal,
    postal_codes: postalCodeLocal,
    tags: tagsLocal,
    show_mls_active: showActivesLocal,
  };

  for (const key in stateVariables) {
    const value = stateVariables[key];

    if (
      value !== undefined &&
      value !== null &&
      value !== "Any" &&
      value !== "All" &&
      // eslint-disable-next-line
      value !== NaN &&
      value !== ""
    ) {
      if (key !== "postal_codes" && key !== "tags" && key !== "mls") {
        queryParams.push(
          `${key}=${encodeURIComponent(String(value)?.replace(/ /g, "_"))}`
        );
      } else if (key === "postal_codes" || key === "tags") {
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      } else if (key === "show_mls_active" && !value) {
        queryParams.push(`${key}=${encodeURIComponent(String(false))}`);
        // eslint-disable-next-line
      } else if (key === "mls" && value && value !== NaN) {
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      }
    }
  }
  const queryString = queryParams.join("&");
  const link = `${queryString}`;

  const setFIlter = () => {
    let newFilter = [
      { Range: `${from_dateLocal} - ${to_dateLocal}` },

      {
        "Distressed Level":
          title === "Distressed Filters"
            ? distressed_levelLocal?.map((level) => level?.split(" ")[0])
            : null,
      },
      {
        Expired:
          title === "Expired Filters" || title === "Old Expired Filters"
            ? expiredStatusLocal
            : null,
      },
      {
        Withdrawn:
          title === "Expired Filters" || title === "Old Expired Filters"
            ? withdrawnStatusLocal
            : null,
      },
      {
        Canceled:
          title === "Expired Filters" || title === "Old Expired Filters"
            ? canceledStatusLocal
            : null,
      },
      {
        Active:
          title === "Distressed Filters" ||
          title === "Pre-Foreclosure Filters" ||
          title === "FSBO Filters"
            ? showActivesLocal
            : activeStatusLocal,
      },
      { Sold: soldStatusLocal },
      { Pending: pendingStatusLocal },
      { "Off Market": offMarketStatusLocal },
      { Released: releasedStatusLocal },
      { Rented: rentedStatusLocal },
      { Unavailable: unavailableStatusLocal },
      { Beds: bedsLocal },
      { Baths: bathsLocal },
      { "Min Price": min_priceLocal },
      { "Max Price": max_priceLocal },
      { "Sq Ft": sq_ftLocal },
      { "Postal Codes": postalCodeLocal },

      { MLS: mlsList.find((obj) => obj.name === mlsREcordLocal)?.name },

      { Tags: tagsLocal },
    ];

    if (title === "Expired Filters" || title === "Old Expired Filters") {
      newFilter.push({
        "Search Date":
          title === "Expired Filters" || title === "Old Expired Filters"
            ? searchDate.find((obj) => obj.name === searchDateTypeLocal)?.name
            : "",
      });
    }
    filter(newFilter);
    params(link);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Modal
      handleOpen={handleOpen}
      open={open}
      mxWidth={"max-w-[620px]"}
      title={title}
    >
      <HDivider />

      <div
        id="focusableDiv"
        className=" overscroll-x-none md:max-h-[calc(70vh-32px)] max-h-[calc(70vh-132px)] overflow-y-scroll max-w-[100vw]"
      >
        <div className=" flex gap-4 flex-grow pt-4 flex-col sm:flex-row">
          <div className=" h-16 gap-1 flex-1">
            <p className="text-[#999999] text-[14px] font-semibold mb-2">
              From
            </p>
            <DatePicker
              onValueChange={setfrom_dateLocal}
              value={isOld ? "8/30/2023" : from_dateLocal}
            />
          </div>
          <div className=" h-16 gap-1 flex-1">
            <p className="text-[#999999] text-[14px] font-semibold mb-2">To</p>
            <DatePicker onValueChange={setto_dateLocal} value={to_dateLocal} />
          </div>
        </div>
        <div className="py-4">
          {status === true && (
            <p className=" font-semibold text-[14px] leading-5 text-[#999999]">
              MLS Status:
            </p>
          )}
          <div className="flex gap-8 mt-3  md:w-[552px] flex-col">
            {status === true && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full">
                <Checkbox
                  title={"Expired"}
                  state={expiredStatusLocal}
                  stateChange={setExpiredStatusLocal}
                />
                <Checkbox
                  title={"Pending"}
                  state={pendingStatusLocal}
                  stateChange={setPendingStatusLocal}
                />
                <Checkbox
                  title={"Withdrawn"}
                  state={withdrawnStatusLocal}
                  stateChange={setWithdrawnStatusLocal}
                />
                <Checkbox
                  title={"Off Market"}
                  state={offMarketStatusLocal}
                  stateChange={setOffMarketStatusLocal}
                />
                <Checkbox
                  title={"Canceled"}
                  state={canceledStatusLocal}
                  stateChange={setCanceledStatusLocal}
                />
                <Checkbox
                  title={"Released"}
                  state={releasedStatusLocal}
                  stateChange={setReleasedStatusLocal}
                />
                <Checkbox
                  title={"Active"}
                  state={activeStatusLocal}
                  stateChange={setActiveStatusLocal}
                />
                <Checkbox
                  title={"Rented"}
                  state={rentedStatusLocal}
                  stateChange={setRentedStatusLocal}
                />
                <Checkbox
                  title={"Sold"}
                  state={soldStatusLocal}
                  stateChange={setSoldStatusLocal}
                />
                <Checkbox
                  title={"Unavailable"}
                  state={unavailableStatusLocal}
                  stateChange={setUnavailableStatusLocal}
                />
              </div>
            )}

            {(title === "Distressed Filters" ||
              title === "Pre-Foreclosure Filters" ||
              title === "FSBO Filters") && (
              <div className=" -mb-4">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  MLS Status:
                </p>

                {
                  <Checkbox
                    title={"Show Actives"}
                    state={showActivesLocal}
                    stateChange={handleShowActives}
                  />
                }
              </div>
            )}

            {title === "Distressed Filters" && (
              <div className=" ">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Level Of Distress
                </p>
                <MultiSelectDropdown
                  rounded={"4px"}
                  SelectionColor={!true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={distressedLevel}
                  initialSelection={[]}
                  onValueChange={handleDistressed}
                  globalSelected={distressedLevelStateLocal}
                  setGlobalSelected={setDistressedLevelStateLocal}

                  //flowWidth={true}
                />
              </div>
            )}

            {title === "Pre-Foreclosure Filters" && (
              <div className=" ">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Pre Foreclosure Status
                </p>
                <MultiSelectDropdown
                  rounded={"4px"}
                  SelectionColor={!true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={PreforeclosureStatusType}
                  initialSelection={PreforeclosureStatusType}
                  onValueChange={setPreFoStateLocal}
                  globalSelected={preFoStateStateLocal}
                  setGlobalSelected={setPreFoStateStateLocal}

                  //flowWidth={true}
                />
              </div>
            )}

            {price && (
              <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
                <div className=" h-16 gap-1 flex-1">
                  <p className="text-[#999999] text-[14px] font-semibold mb-2">
                    Min Price
                  </p>
                  <PrimaryInput
                    placeHolder={"0"}
                    type={"number"}
                    value={min_priceLocal}
                    onValueChange={setmin_priceLocal}
                  />
                </div>
                <div className=" h-16 gap-1 flex-1">
                  <p className="text-[#999999] text-[14px] font-semibold mb-2">
                    Max Price
                  </p>
                  <PrimaryInput
                    placeHolder={"0"}
                    type={"number"}
                    value={max_priceLocal}
                    onValueChange={setmax_priceLocal}
                  />
                </div>
              </div>
            )}
            <div className="w-full">
              <p className="text-[#999999] text-[14px] font-semibold mb-2">
                Tags
              </p>
              <PrimaryInput
                onValueChange={setTagsLocal}
                placeHolder={"Ex. tag"}
                value={tagsLocal}
              />
              <p className="text-[12px] leading-4 text-[#999999]">
                Separate tags with spaces to match all, or commas to match any
              </p>
            </div>

            <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
              <div className=" h-16 gap-1 flex-1">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Beds
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={false}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={bedsList}
                  initialSelection={0}
                  flowWidth={true}
                  onValueChange={setbedsLocal}
                  globalSelected={bedStateLocal}
                  setGlobalSelected={setBedStateLocal}
                />
              </div>
              <div className=" h-16 gap-1 flex-1">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Bath
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={false}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={bathsList}
                  initialSelection={0}
                  flowWidth={true}
                  onValueChange={setbathsLocal}
                  globalSelected={bathStateLocal}
                  setGlobalSelected={setbathStateLocal}
                />
              </div>
            </div>

            <div className=" h-16 gap-1">
              <p className="text-[#999999] text-[14px] font-semibold mb-2">
                Square Feet
              </p>
              <Selection
                rounded={"4px"}
                SelectionColor={false}
                selecionHeight={"36px"}
                setIsActive={() => {}}
                list={squareFeet}
                initialSelection={0}
                flowWidth={true}
                onValueChange={setsq_ftLocal}
                globalSelected={sq_ftStateLocal}
                setGlobalSelected={setsq_ftStateLocal}
              />
            </div>

            <div className=" h-16 mb-4  w-full flex flex-col">
              <p className="text-[#999999] text-[14px] font-semibold mb-2">
                Postal Code(s)
              </p>
              <PrimaryInput
                placeHolder={"Ex. 84043"}
                onValueChange={setPostslCodeLocal}
                value={postalCodeLocal}
              />
              <p className="text-[12px] leading-4 text-[#999999]">
                Enter Postal codes separated by commas
              </p>
            </div>
            {hasSearchDate && (
              <div className=" h-16 gap-1">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Search Date
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={searchDate}
                  initialSelection={0}
                  onValueChange={setSearchDateTypeLocal}
                  globalSelected={searchDateStateLocal}
                  setGlobalSelected={setSearchDateStateLocal}
                  //flowWidth={true}
                />
              </div>
            )}

            {mls && (
              <div className=" h-16 gap-1 mb-[48px]">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  MLS
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={false}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={mlsList}
                  initialSelection={0}
                  flowWidth={true}
                  onValueChange={setMlsRecordLocal}
                  globalSelected={mlsRecordStateLocal}
                  setGlobalSelected={setMlsRecordStateLocal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <HDivider />
      <div className=" h-10 my-8 flex justify-between ">
        <SecondaryButtons label={"Cancel"} onClick={handleOpen} />
        <PrimaryButtons
          label={"Apply Filter"}
          onClick={(e) => {
            addFilter();
            handleOpen();
          }}
        />
      </div>
    </Modal>
  );
};

export default FilterPage;
