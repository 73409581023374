import React from "react";
import Modal from "../../common/Modal";

const FsboErrorPopUp = ({ handleOpen, open, data }) => {
  return (
    <Modal
      handleOpen={handleOpen}
      open={open}
      mxWidth={"max-w-[600px]"}
      title={"FSBO Area Limitation"}
    >
      <div className=" overscroll-x-none max-h-[calc(70vh-32px)] overflow-y-scroll ">
        <div className=" flex flex-col gap-12">
          <div>
            <div className=" flex flex-col gap-4">
              <p className="text-[14px] text-wrap">{data}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FsboErrorPopUp;
