import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import Selection from "../utils/account/selectForPagination";

const Pagination = ({
  pages = [],
  currentPage = 6,
  scrollIntoView = () => {},
  onPageChange,
  perPage,
}) => {
  const [lastPage, setLastPage] = useState(0);
  useEffect(() => {
    setLastPage(pages.length);
  }, [perPage.value, pages.length]);

  useEffect(() => {
    scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <>
      <div className="flex justify-end gap-1 items-center">
        <Selection
          currentPage={currentPage}
          lastPage={lastPage}
          list={[]}
          setPage={onPageChange}
        />
        <div className="flex gap-4 items-center -space-x-px h-10 text-base">
          <div>
            <button
              className="flex cursor-pointer items-center justify-center px-2 h-10 ms-0 leading-tight text-gray-500 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
              onClick={(e) => {
                if (currentPage > 1) onPageChange(currentPage - 1);
              }}
            >
              <span className="sr-only">Previous</span>
              <FaIcons.FaChevronLeft />
            </button>
          </div>

          <button
            className="flex cursor-pointer items-center justify-center px-2 h-10 ms-0 leading-tight text-gray-500 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
            onClick={(e) => {
              if (currentPage < lastPage) onPageChange(currentPage + 1);
            }}
          >
            <span className="sr-only">Next</span>
            <FaIcons.FaChevronRight />
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
