import React from "react";
import { ContactCard } from "./amenities";

const ListContact = ({ processedContacts, property }) => {
  return (
    <div className="lg:flex   flex-col h-full xl:py-6 sm:min-w-[320px] xl:w-[320px]">
      <p className=" text-[14px] text-[#666666] leading-6 font-semibold mb-4">
        Contacts ({processedContacts?.length})
      </p>
      <div className=" w-[calc(100vw-24px)] overflow-none xl:w-[380px] hideScrollBar">
        <div className={`w-[calc(100vw-12px)] gap-y-3  xl:w-[370px]`}>
          <div className=" grid xl:grid-cols-2 grid-cols-2 gap-y-3   ">
            {processedContacts
              // .slice(0, 4)
              .map(
                (item, index) =>
                  index <= 3 && (
                    <ContactCard
                      id={`contatct ${index}`}
                      name={`${item.first_name} ${item.last_name}`}
                      number={item.phone}
                      dnc={item.phone_dnc}
                      type={item?.phone_type}
                      property_id={property?.property?.id}
                      isStared={item?.star}
                      list={"expired"}
                      hidden={{
                        hidden: item?.hidden,
                        tell: item.phone,
                      }}
                    />
                  )
              )}
            {/* {property?.contacts.length > 3 && (
                <div className=" h-[60.5px] w-[150px] flex justify-center items-center">
                  <p className=" text-[12px] font-bold  text-#666666] leading-4">
                    See All{" "}
                    {`(${property?.contacts.length - 3})`}
                  </p>
                </div>
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListContact;
