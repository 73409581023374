const removeDuplicatesAndMarkOriginals = (contacts) => {
  const uniqueContacts = [];
  const seenNumbers = new Map();

  contacts.forEach((contact) => {
    if (!seenNumbers.has(contact?.phone)) {
      seenNumbers.set(contact?.phone, uniqueContacts?.length);
      uniqueContacts.push({ ...contact, star: false });
    } else {
      const originalIndex = seenNumbers.get(contact?.phone);
      const originalContact = uniqueContacts[originalIndex];

      // Check if the original contact's phone_type is 'U' or 'u'
      if (
        originalContact?.phone_type === "U" ||
        originalContact?.phone_type === "u"
      ) {
        // Check if the current contact's phone_type is not 'U' or 'u'
        if (contact?.phone_type !== "U" && contact?.phone_type !== "u") {
          // Update the original contact's phone_type
          originalContact.phone_type = contact?.phone_type;
        }
      }

      // Mark the original contact with a star
      originalContact.star = true;
    }
  });

  return uniqueContacts;
};

const updateTagsInListing = (newTagsArray, currentMLS, setListing) => {
  const listingId = currentMLS;
  setListing((currentListings) =>
    currentListings.map((listing) => {
      // Check if this is the listing to update
      if (listing?.id === listingId || listing?.publish_id === listingId) {
        // Clone the listing to avoid direct mutations and update the tags array
        return { ...listing, tags: [...newTagsArray] };
      }
      // Return the listing unchanged if it's not the one to update
      return listing;
    })
  );
};

const updateNotes = (newNotes, currentMLS, setListing) => {
  const listingId = currentMLS;
  setListing((currentListings) =>
    currentListings.map((listing) => {
      // Check if this is the listing to update
      if (listing?.id === listingId || listing?.publish_id === listingId) {
        // Clone the listing to avoid direct mutations and update the tags array
        return { ...listing, notes: newNotes };
      }
      // Return the listing unchanged if it's not the one to update
      return listing;
    })
  );
};

// {
//   "note": "hello",
//   "create_date": "2024-10-07 13:09:15",
//   "id": 505126,
//   "modify_date": "2024-10-07 13:09:15"
// } fsbo notes

// {
//   "note": "note",
//   "create_date": "2024-10-07 13:05:16",
//   "id": 2710475,
//   "modify_date": "2024-10-07 13:05:16",
//   "title": "1728327916512"
// }

export { removeDuplicatesAndMarkOriginals, updateTagsInListing, updateNotes };
