import React from "react";
import { NavLink } from "react-router-dom";
import {
  InstagramDisabled,
  FacebookDisabled,
  XDisabled,
  LinkedInDisabled,
  XActive,
  FacebookActive,
  LinkedInActive,
  InstagramActive,
} from "@landvoice/icons";

const SocialMedia = (owner) => {
  owner = owner?.owner?.where ? owner?.owner : owner;

  return (
    <div className="flex gap-[20px] items-center">
      {owner?.facebook ? (
        <NavLink to={owner?.facebook} target="blank">
          <FacebookActive />{" "}
        </NavLink>
      ) : (
        <FacebookDisabled />
      )}
      {owner?.instagram ? (
        <NavLink to={owner?.facebook} target="blank">
          {" "}
          <InstagramActive />
        </NavLink>
      ) : (
        <InstagramDisabled />
      )}
      {owner?.twitter ? (
        <NavLink to={owner?.twitter} target="blank">
          <XActive />
        </NavLink>
      ) : (
        <XDisabled />
      )}
      {owner?.linkedin ? (
        <NavLink to={owner?.linkedin} target="blank">
          <LinkedInActive />
        </NavLink>
      ) : (
        <LinkedInDisabled />
      )}
    </div>
  );
};

export default SocialMedia;
