import React from "react";

const DistressRate = ({ rate }) => {
  return (
    <>
      <div className="flex lg:gap-4 gap-2 items-center">
        <p className=" font-semibold text-[12px] lg:leading-4 md:leading-3 text-[#999999]">
          Level of Distress
        </p>
        <div className="flex gap-1">
          <div
            className={`h-2 md:w-20 w-14 rounded-lg bg-[#F6E3D2] ${
              parseInt(rate) > 0 ? "bg-[#F6E3D2]" : "bg-[#F5F7F7]"
            }`}
          ></div>
          <div
            className={`h-2 md:w-20 w-14 rounded-lg  ${
              parseInt(rate) > 1 ? "bg-[#FDCFB8]" : "bg-[#F5F7F7]"
            }`}
          ></div>
          <div
            className={`h-2 md:w-20 w-14 rounded-lg  ${
              parseInt(rate) > 2 ? "bg-[#F6A77F]" : "bg-[#F5F7F7]"
            }`}
          ></div>
          <div
            className={`h-2 md:w-20 w-14 rounded-lg  ${
              parseInt(rate) > 3 ? "bg-[#FA7532]" : "bg-[#F5F7F7]"
            }`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default DistressRate;
