import React, { useEffect, useState } from "react";
import copyTextToClipboard from "./copyToCLipBoard";
import { formatPhoneNumber } from "./amenities";
import { SvgBusiness, SvgHome, SvgMobile } from "./svg";
import useLongPress from "./UseLongPress";
import useAPI from "./UseApi";

const ContactCard = ({
  name,
  number,
  dnc,
  isStared,
  hidden,
  click,
  property_id,
  list,
  type,
}) => {
  const { post } = useAPI();
  const [Hidden, setHidden] = useState(hidden);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  useEffect(() => {
    setHidden(hidden);
    // eslint-disable-next-line
  }, [number]);

  const onLongPress = () => {
    const phoneUrl = `tel:${number}`;
    window.location.href = phoneUrl;
  };

  const onClick = (click = () => {}) => {
    click();

    post(
      `/api/${list}/hide-phone`,
      {
        phone: number,
        property_id,
        hidden: !Hidden.hidden,
      },
      () => {}
    );
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    onLongPress,
    () => {
      onClick(click);
      setHidden({ hidden: !Hidden.hidden, tell: hidden.tell });
    },
    defaultOptions
  );

  return (
    <div className=" relative  flex w-fit ">
      <div
        onContextMenu={handleContextMenu}
        {...longPressEvent}
        className={
          `h-[60.5px]  min-w-[150px]  rounded-lg py-[8px] ${
            isStared ? "border-[#36B25F] border-2" : "border-[#999999] border"
          }  border-r-0 rounded-r-none pl-[8px] flex gap-[4px] items-center text-[#666666] ` +
          (Hidden.hidden
            ? "opacity-50 crossOut"
            : " hover:text-[#F27935] hover:border-[#F27935] ")
        }
      >
        <div className="flex flex-col gap-[4px]">
          {name && name !== " " ? (
            <p className="font-bold text-[11px]  leading-4">
              {name.toUpperCase()}
            </p>
          ) : (
            <p className="font-bold text-[12px]  leading-4 italic">
              no name found
            </p>
          )}
          <div className={`flex gap-1 items-end`}>
            {
              //icon &&
              type === "M" && (
                <SvgMobile
                  className={`md:text-[12px] text-[12px] ${
                    dnc
                      ? "text-[#FA423C] fill-[#FA423C] "
                      : "text-[#36B25F] fill-[#36B25F]"
                  }`}
                />
              )
            }
            {
              //icon &&
              type === "B" && (
                <SvgBusiness
                  className={`md:text-[12px] text-[12px] ${
                    dnc
                      ? "text-[#FA423C] fill-[#FA423C] "
                      : "text-[#36B25F] fill-[#36B25F]"
                  }`}
                />
              )
            }
            {
              //icon &&
              type === "R" && (
                <SvgHome
                  className={`md:text-[12px] text-[12px] ${
                    dnc
                      ? "text-[#FA423C] fill-[#FA423C] "
                      : "text-[#36B25F] fill-[#36B25F]"
                  }`}
                />
              )
            }
            <div className={Hidden.hidden ? " relative" : ""}>
              <p
                className={
                  `  text-[11px]  leading-3  relative text-nowrap ` +
                  (dnc
                    ? "text-[#FA423C] fill-[#FA423C] "
                    : "text-[#36B25F] fill-[#36B25F] font-[700]") +
                  (Hidden.hidden ? " " : "")
                }
              >
                {" "}
                {formatPhoneNumber(number)}
              </p>
            </div>
            {dnc && (
              <p className="text-[10px]  leading-3  text-[#FA423C] font-bold">
                DNC
              </p>
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          if (!Hidden.hidden) copyTextToClipboard(number);
        }}
        className={
          `h-[60.5px] max-w-[25px] min-w-[25px] border rounded-lg  border-l-0 rounded-l-none flex justify-center items-center text-[#666666] ${
            isStared === true
              ? "border-[#36b25F] border-2"
              : "border-[#999999] border"
          } ` +
          (Hidden.hidden
            ? "opacity-50 crossOut"
            : " hover:text-[#F27935] hover:border-[#F27935] hover:bg-[#f27a3520] ")
        }
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1023_30675)">
            <path
              d="M23.5594 3.31078L20.6883 0.439687C20.4094 0.158016 20.025 0 19.6265 0H11.9578C10.3008 0 8.95779 1.34344 8.95779 3L8.95871 15C8.99998 16.6594 10.3453 18 12 18H21C22.65 18 24 16.65 24 15V4.37109C24 3.97359 23.8406 3.59203 23.5594 3.31078ZM21.75 15C21.75 15.4142 21.4142 15.75 21 15.75H11.9578C11.5435 15.75 11.2078 15.4142 11.2078 15V3.00609C11.2078 2.59191 11.5436 2.25609 11.9578 2.25609H17.9578L18 4.5C18 5.32828 18.6717 6 19.5 6H21.7078V15H21.75ZM12.75 21C12.75 21.4142 12.4142 21.75 12 21.75H2.95779C2.54351 21.75 2.20779 21.4142 2.20779 21L2.24904 9.00469C2.24904 8.5905 2.58486 8.25469 2.99904 8.25469H7.49998V6H2.99951C1.34248 6 -0.000488281 7.34297 -0.000488281 9L-2.89062e-05 21C7.42187e-05 22.6547 1.34342 24 2.99998 24H12C13.65 24 15 22.65 15 21V19.5H12.7922L12.75 21Z"
              fill="#666666"
            />
          </g>
          <defs>
            <clipPath id="clip0_1023_30675">
              <rect width="24" height="24" fill="#666666" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default ContactCard;
