import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { PrimaryButtons, SecondaryButtons } from "./buttons";
import useAPI from "./UseApi";
import { formatTimestamp } from "./amenities";
import { Close } from "@landvoice/icons";
import Cookies from "universal-cookie";

const AddNotes = ({
  handleOpen,
  open,
  notes = [],
  postTo,
  currentMLS,
  setListing,

  // eslint-disable-next-line react-hooks/exhaustive-deps
  where = "expired",
  updateNotes = (note) => {},
}) => {
  const [note, setNote] = useState(notes[0]?.note);
  const [allNotes, setAllNotes] = useState([]);
  const [CSRFToken, setCSRFToken] = useState("0");

  useEffect(() => {
    const cookies = new Cookies();
    const cookieValue = cookies.get("csrftoken");
    setCSRFToken(cookieValue);
  }, []);

  const { post } = useAPI();
  useEffect(() => {
    if (open) {
      //setNote(notes[0]?.note);
      setAllNotes(notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const clear = () => {
    setNote("");
    handleOpen({ id: 0 });
  };

  const saveNote = async () => {
    const currentDate = new Date();
    const currentTimeMilliseconds = currentDate.getTime();
    if (!note || note.length === 0) return handleOpen({ id: 0 });
    const newNote = await post(
      postTo,
      {
        title: currentTimeMilliseconds,
        note: note,
      },
      () => {} // This empty function might be unnecessary if 'post' returns a promise
    );
    if (note) updateNotes([newNote, ...notes], currentMLS, setListing);
    if (note) clear();
  };

  const deleteNote = (itemId) => {
    // Confirm the deletion with the user
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item, action can't be undone?"
    );
    if (!isConfirmed) {
      return;
    }

    fetch(`api/${where}/note/${itemId}`, {
      method: "DELETE", // Specify the method to use
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": CSRFToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Update the UI by filtering out the deleted item
        const updatedItems = allNotes.filter((item) => item.id !== itemId);
        setAllNotes(updatedItems);
        updateNotes(updatedItems, currentMLS, setListing);
      })
      .catch((error) =>
        console.error("There was an error deleting the item", error)
      );
  };

  return (
    <Modal
      handleOpen={clear}
      open={open}
      mxWidth={"max-w-[552px]"}
      title={"Add Notes"}
    >
      <div className="max-w-[552px] min-h-[176px] py-4">
        <p className="text-[#999999] font-semibold leading-5">Add Note</p>
        <div className="flex justify-between gap-2 h-[200px]">
          <textarea
            className=" relative  w-full bg-[#F5F7F7] py-2 px-3 border border-[#E0E0E0] rounded placeholder:text-[#999999] text-[14px] leading-6 text-[#666666] outline-[#E2631B] resize-none"
            type="text"
            placeholder="Enter your note here"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
              notes = note;
            }}
          />
        </div>
        <div className="max-h-[200px] overflow-y-scroll mt-4 ">
          {(allNotes || []).map((note, index) => {
            return (
              <div
                key={`note ${index}`}
                className="flex  justify-between py-1 pt-1 border-[#ebebeb] gap-2  border-b-2"
              >
                <p className="text-[11px] font-bold text-[#666666] leading-4 max-w-[420px]">
                  {note?.note}
                </p>
                <div className=" flex gap-5 ">
                  <p className="text-[11px] font-bold text-[#999] leading-4">
                    {formatTimestamp(note.create_date)}
                  </p>
                  <div
                    onClick={() => {
                      deleteNote(note.id);
                    }}
                  >
                    <Close className={"text-[12px]"} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className=" flex justify-between mt-8">
        <SecondaryButtons label={"Cancel"} onClick={clear} />
        <PrimaryButtons label={"Save"} onClick={saveNote} />
      </div>
    </Modal>
  );
};

export default AddNotes;
