const formatDate = (inputDate) => {
  if (inputDate) {
    const dateParts = inputDate?.split("-" || "/");
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
    const year = parseInt(dateParts[0], 10);

    const date = new Date(year, month - 1, day);

    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  }
};

export default formatDate;
