import React, { useEffect, useState } from "react";
import { useSidebar } from "../utils/sidebar/SideBarContext";
import Sidebar from "../utils/sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth/assist/authContext";
import Banner from "./banner";
import insp, { install } from "inspectlet-es";
import LoginAs from "./loginAs";
import TOSModal from "./TOSModal";

const PageLayout = ({ children, pageBG, hideSideBar, detailsOpen = false }) => {
  install(1808888316);

  const { isAuth, prelogin, user, hasAcceptedTerms, acceptedTOS } = useAuth();

  const location = useLocation();
  const currentPage = location.pathname;
  const [isLogedInAs, setIsLogedInAs] = useState(false);
  const [showTos, setShowTos] = useState(false);

  const { isSidebarOpen } = useSidebar();

  const navigate = () => {
    if (
      currentPage !== "/signin" &&
      currentPage !== "/forgotpassword" &&
      !location.pathname.startsWith("/forgotpassword/") &&
      !isAuth
    ) {
      const redirectLink = document.createElement("a");
      redirectLink.href = "/signin";
      document.body.appendChild(redirectLink);
      if (!isAuth) {
        redirectLink.click();
      }
    }
  };

  useEffect(() => {
    insp(["identify", user?.email]);
    // window.__insp.push(['identify', "john@example.com"]);
  }, [user?.email]);

  useEffect(() => {
    prelogin(() => navigate());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, currentPage]);

  useEffect(() => {
    ShowTerms();
  });

  const ShowTerms = () => {
    if (!isAuth) return false;
    if (isLogedInAs) {
      setShowTos(false);
      return false;
    }
    if (hasAcceptedTerms) {
      setShowTos(false);
      return false;
    }
    setShowTos(true);
  };
  return (
    <div className="hideScrollBar">
      {showTos && (
        <div className=" bg-hero-pattern h-screen w-screen bg-cover z-[9999999999999] fixed overflow-y-scroll ">
          <TOSModal
            action={() => acceptedTOS}
            open={true}
            title={"Landvoice License And Service Agreement"}
          ></TOSModal>
        </div>
      )}

      <LoginAs setLoginAs={setIsLogedInAs} />
      {currentPage === "/signin" ||
      currentPage === "/forgotpassword" ||
      location.pathname.startsWith("/forgotpassword/") ||
      (isAuth && !showTos) ? (
        <main
          className={
            !isSidebarOpen && !hideSideBar
              ? `md:ml-[230px] print:md:ml-0 pageLayout ${pageBG} bg-cover  ${
                  detailsOpen ? "max-h-screen" : "hideScrollBar"
                }`
              : isSidebarOpen && !hideSideBar
              ? `md:ml-[88px] print:ml-0 pageLayout ${pageBG} bg-cover  ${
                  detailsOpen ? "max-h-screen" : "hideScrollBar"
                }`
              : `ml-[0px] authPageLayout ${pageBG} bg-cover  ${
                  detailsOpen ? "max-h-screen" : "hideScrollBar"
                }`
          }
        >
          {hideSideBar ? "" : <Sidebar detailsOpen={detailsOpen} />}
          {currentPage === "/signin" ||
          currentPage === "/forgotpassword" ||
          location.pathname.startsWith("/forgotpassword/") ? (
            ""
          ) : (
            <>
              <Banner />
            </>
          )}

          <div className={detailsOpen ? "hideScrollBar" : ""}>{children}</div>
        </main>
      ) : null}
    </div>
  );
};

export default PageLayout;
