const SvgMapSolidV20 = ({ title, titleId, ...props }) => (
  <svg
    height="20px"
    viewBox="0 0 24 22"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
    className=" fill-[#666666] hover:fill-[#F27935] cursor-pointer inline"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17 5.333c0 2.275-3.046 6.33-4.383 7.963-.321.437-.913.437-1.234 0C10.046 11.663 7 7.608 7 5.333a5 5 0 1 1 10 0Zm-5 1.334a1.666 1.666 0 1 0 0-3.333 1.666 1.666 0 0 0 0 3.333Zm5.733 1.158c.021-.05.042-.1.063-.154l4.833-1.933A1 1 0 0 1 24 6.667V17.95c0 .408-.25.775-.63.93l-6.037 2.412V8.683c.146-.287.28-.57.4-.858Zm-11.466 0c.12.288.254.57.4.858v10.475L1.37 21.28C.715 21.504 0 21.06 0 20.35V9.067c0-.409.249-.813.629-.93l5.104-2.041c.1.592.304 1.179.534 1.729Zm7.391 6.304A35.162 35.162 0 0 0 16 10.96v10.387l-8-2.288v-8.1a35.156 35.156 0 0 0 2.342 3.171 2.074 2.074 0 0 0 3.316 0Z" />
  </svg>
);

const SvgZillow = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
    className="ml-2 fill-[#666666] hover:fill-[#1277E1] cursor-pointer inline"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.028 4.315c.073-.017.105.008.15.057.252.28 1.058 1.264 1.277 1.532.04.049.012.102-.02.13C9.808 7.303 7.998 9.1 6.99 10.385c-.02.03-.004.03.012.025 1.757-.757 5.884-1.965 7.743-2.302V5.754L7.47 0 .184 5.75v2.573c2.256-1.34 7.477-3.42 9.844-4.008Z" />
    <path d="M4.108 14.203c-.061.033-.11.024-.16-.024L2.592 12.56c-.037-.045-.041-.07.008-.138 1.05-1.537 3.192-3.932 4.558-4.945.025-.016.017-.036-.012-.024-1.423.464-5.469 2.204-6.965 3.05V16H14.74v-5.294c-1.984.337-7.929 2.106-10.633 3.497Z" />
  </svg>
);

const SvgMobile = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M17.25 0H6.75C5.09297 0 3.75 1.34297 3.75 3V21C3.75 22.657 5.09297 24 6.75 24H17.25C18.907 24 20.25 22.657 20.25 21V3C20.25 1.34297 18.9047 0 17.25 0ZM18 21C18 21.4135 17.6635 21.75 17.25 21.75H6.75C6.33656 21.75 6 21.4125 6 21V3C6 2.58647 6.33647 2.25 6.75 2.25H17.25C17.6625 2.25 18 2.58656 18 3V21ZM13.5 18.75H10.5C10.0858 18.75 9.75 19.0858 9.75 19.5C9.75 19.9142 10.0858 20.25 10.5 20.25H13.5C13.9143 20.25 14.25 19.9142 14.25 19.5C14.25 19.0858 13.9125 18.75 13.5 18.75Z" />
  </svg>
);

const SvgBusiness = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33333 18.6666H8C8.73417 18.6666 9.33333 18.0674 9.33333 17.3333V2.66659C9.33333 1.93242 8.73417 1.33325 8 1.33325H5.33333C4.6 1.33325 4 1.93242 4 2.66659V17.3333C4 18.0666 4.6 18.6666 5.33333 18.6666ZM22 2.66659H10.6667V17.3333C10.6667 18.802 9.46875 19.9999 8 19.9999H5.33333C3.86458 19.9999 2.66667 18.802 2.66667 17.3333V2.66659H2C0.895833 2.66659 0 3.56242 0 4.66659V20.6666C0 21.7708 0.895833 22.6666 2 22.6666H22C23.1042 22.6666 24 21.7708 24 20.6666V4.66659C24 3.56242 23.1042 2.66659 22 2.66659ZM16 19.3333C16 19.703 15.7031 19.9999 15.3333 19.9999H14C13.6302 19.9999 13.3333 19.703 13.3333 19.3333V17.9999C13.3333 17.6301 13.6302 17.3333 14 17.3333H15.3333C15.7031 17.3333 16 17.6301 16 17.9999V19.3333ZM16 13.9999C16 14.3697 15.7031 14.6666 15.3333 14.6666H14C13.6292 14.6666 13.3333 14.3708 13.3333 13.9999V12.6666C13.3333 12.2958 13.6292 11.9999 14 11.9999H15.3333C15.7042 11.9999 16 12.2958 16 12.6666V13.9999ZM21.3333 19.3333C21.3333 19.703 21.0365 19.9999 20.6667 19.9999H19.3333C18.9635 19.9999 18.6667 19.703 18.6667 19.3333V17.9999C18.6667 17.6301 18.9635 17.3333 19.3333 17.3333H20.6667C21.0365 17.3333 21.3333 17.6301 21.3333 17.9999V19.3333ZM21.3333 13.9999C21.3333 14.3697 21.0365 14.6666 20.6667 14.6666H19.3333C18.9625 14.6666 18.6667 14.3708 18.6667 13.9999V12.6666C18.6667 12.2958 18.9625 11.9999 19.3333 11.9999H20.6667C21.0375 11.9999 21.3333 12.2958 21.3333 12.6666V13.9999ZM21.3333 9.33325H13.3333V5.33325H21.3333V9.33325Z"
      fill="#666666"
    />
  </svg>
);

const SvgHome = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6458 9.90421C24.0667 10.2625 24.1208 10.8917 23.7625 11.3125C23.4042 11.7334 22.775 11.7875 22.3542 11.4292L21.3333 10.5625V18.3334C21.3333 20.175 19.8417 21.6667 18 21.6667H6C4.15916 21.6667 2.66667 20.175 2.66667 18.3334V10.5625L1.64708 11.4292C1.22583 11.7875 0.594998 11.7334 0.237498 11.3125C-0.119793 10.8917 -0.0680851 10.2625 0.35304 9.90421L11.3542 0.570874C11.725 0.254207 12.275 0.254207 12.6458 0.570874L23.6458 9.90421ZM6 19.6667H8V13.3334C8 12.4125 8.74583 11.6667 9.66667 11.6667H14.3333C15.2542 11.6667 16 12.4125 16 13.3334V19.6667H18C18.7375 19.6667 19.3333 19.0709 19.3333 18.3334V8.86671L12 2.64462L4.66667 8.86671V18.3334C4.66667 19.0709 5.2625 19.6667 6 19.6667ZM10 19.6667H14V13.6667H10V19.6667Z"
      fill="#666666"
    />
  </svg>
);

export { SvgHome, SvgBusiness, SvgMapSolidV20, SvgMobile, SvgZillow };
