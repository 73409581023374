const formatPhoneNumber = (phoneNumber) => {
  if (typeof phoneNumber !== "string" || phoneNumber.trim() === "") {
    return "";
  }
  const cleaned = phoneNumber.replace(/[^\dX]/g, "");
  if (cleaned.length !== 10) {
    return cleaned;
  }

  return cleaned.replace(/^(\d{3})(\d{3})(\d{4}|XXXX)$/, "$1-$2-$3");
};

export default formatPhoneNumber;
