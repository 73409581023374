const copyTextToClipboard = async (textToCopy) => {
  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy text");
    }
  } else {
    // Fallback for older browsers
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy text");
    }
    document.body.removeChild(textarea);
  }
};

export default copyTextToClipboard;
