// Helper function to escape special characters in a string for use in a regular expression
function escapeRegExp(input) {
  // Check if input is a string; if not, convert it to a string
  if (typeof input !== "string") {
    console.warn(
      `Expected a string but received ${typeof input}. Converting to string.`
    );
    input = String(input); // Convert input to string if not already a string
  }

  // Replace special characters with escaped versions
  return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

// Function to remove a specific parameter (key-value pair) from a query string
function removeParamFromString(str, key, value) {
  // Check if inputs are strings; if not, convert them to strings
  if (typeof str !== "string") {
    console.warn(
      `Expected a string for 'str' but received ${typeof str}. Converting to string.`
    );
    str = String(str);
  }

  if (typeof key !== "string") {
    console.warn(
      `Expected a string for 'key' but received ${typeof key}. Converting to string.`
    );
    key = String(key);
  }

  if (typeof value !== "string") {
    console.warn(
      `Expected a string for 'value' but received ${typeof value}. Converting to string.`
    );
    value = String(value);
  }

  // Escape the key and value to ensure they are safe to use in a regular expression
  const escapedKey = escapeRegExp(key);
  const escapedValue = escapeRegExp(value);

  // Create a regular expression to match the key-value pair in the query string
  const regex = new RegExp("&" + escapedKey + "=" + escapedValue, "g");

  // Return the modified string with the key-value pair removed
  return str.replace(regex, "");
}

export default removeParamFromString;
