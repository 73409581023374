import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import { useAuth } from "../auth/assist/authContext";

import logoutIcon from "../../../assets/right-from-bracket-solid.svg";
import logo from "../../../assets/Landvoice Logo 2.png";
import Xmark from "../../../assets/xmark.svg";
import Bars from "../../../assets/bars.svg";

const MobileView = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const { logout, user } = useAuth();

  return (
    <>
      <div className="landvoice-logo logo-M z-[999999] bg-[#212a33] print:bg-transparent print:hidden print:justify-end  ">
        <img
          height={"24px"}
          width={"24px"}
          src={!sidebar ? Bars : Xmark}
          onClick={showSidebar}
          alt=""
        />
        <img height={"24px"} width={"24px"} src={logo} alt="" />{" "}
        <p className={" print:text-[#000000]"}>
          LAND<span>VOICE</span>
        </p>
      </div>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className=" relative">
          {sidebar && (
            <div
              onClick={showSidebar}
              className="h-screen w-screen fixed  bg-slate-500 opacity-10 z-0  "
            />
          )}
          <nav
            className={
              sidebar ? "nav-menu-M open  relative z-[999999999]" : "nav-menu-M"
            }
          >
            <ul className="nav-menu-items overflow-y-scroll z-10">
              <p className={"nav-text-M title-M"}>Your Lists</p>

              <NavLink to="/expired" className={"nav-text"}>
                <div className="nav-button">
                  <FaIcons.FaUserClock />
                  <span className={""}>Expired</span>
                </div>
              </NavLink>

              {user.expired_upload && (
                <NavLink to="/expireduploads" className={"nav-text"}>
                  <div className="nav-button">
                    <FaIcons.FaClock />
                    <span className={""}>Expired Uploads</span>
                  </div>
                </NavLink>
              )}

              <NavLink to="/OldExpired" className={"nav-text"}>
                <div className="nav-button">
                  <FaIcons.FaUserAltSlash />
                  <span className={""}>Old Expired</span>
                </div>
              </NavLink>

              <NavLink to="/fsbo" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaSign />}
                  <span className={""}>{"FSBO"}</span>
                </div>
              </NavLink>

              <NavLink to="/preforeclosure" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaHome />}
                  <span className={""}>{"Pre-Foreclosure"}</span>
                </div>
              </NavLink>

              <NavLink to="/Distressed" className={"nav-text"}>
                <div className="nav-button">
                  {
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className=""
                      fill="#fff"
                    >
                      <path d="M10.8 15.5288V15.6H9.6C8.93625 15.6 8.4 16.1363 8.4 16.8V20.1C8.4 20.9288 7.72875 21.6 6.9 21.6H4.80375C4.7475 21.6 4.69125 21.5963 4.635 21.5925C4.59 21.5963 4.545 21.6 4.5 21.6H3.9C3.07163 21.6 2.4 20.9288 2.4 20.1V15.9C2.4 15.8663 2.40112 15.8288 2.40337 15.795V13.185H1.20187C0.52575 13.185 0 12.6563 0 11.9813C0 11.6438 0.11265 11.3438 0.375375 11.0813L9.99 2.70062C10.2525 2.4376 10.5525 2.40002 10.815 2.40002C11.0775 2.40002 11.3775 2.47517 11.6063 2.66305L16.7738 7.20377C16.3088 7.17002 15.8363 7.31627 15.4688 7.64627C14.1413 8.83127 13.0088 10.185 12.195 11.5013C11.4113 12.7688 10.8 14.2088 10.8 15.5288ZM19.0875 10.7063C19.3838 10.335 19.7175 9.93003 20.0813 9.56628C20.2913 9.40877 20.6213 9.40877 20.8313 9.60002C21.7575 10.4513 22.5413 11.5763 23.0925 12.645C23.64 13.695 24 14.7713 24 15.5288C24 18.8213 21.3263 21.6 18 21.6C14.6363 21.6 12 18.8175 12 15.5288C12 14.5388 12.4763 13.3313 13.215 12.1313C13.965 10.92 15.0188 9.65252 16.2675 8.53877C16.4775 8.35127 16.7663 8.35502 17.0063 8.54252C17.7488 9.21002 18.4425 9.92627 19.0875 10.7063ZM20.625 15.0038C20.55 14.8538 20.475 14.7038 20.3625 14.5538L19.0125 16.1625C19.0125 16.1625 16.8375 13.3875 16.6875 13.2C15.5625 14.5538 15 15.375 15 16.275C15 18.1125 16.35 19.2 18.0375 19.2C18.7125 19.2 19.3125 19.0125 19.875 18.6038C21 17.85 21.3 16.275 20.625 15.0038Z" />
                    </svg>
                  }
                  <span className={""}>{"Distressed"}</span>
                </div>
              </NavLink>

              {/* {SidebarData.map((item, index) => {return ();})} */}

              <p className={"nav-text title-M"}>Other Resources</p>

              <NavLink
                to="https://search.landvoice.com/"
                className={"nav-text"}
              >
                <div className="nav-button">
                  {<FaIcons.FaMap />}
                  <span className={""}>{"Neighborhood Search"}</span>
                </div>
              </NavLink>

              <NavLink
                to="https://callcapture.landvoice.com/account/dashboard"
                className={"nav-text"}
              >
                <div className="nav-button">
                  {<FaIcons.FaPhone />}
                  <span className={""}>{"Call Capture"}</span>
                </div>
              </NavLink>

              <NavLink to="/scripts" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaFile />}
                  <span className={""}>{"Scripts"}</span>
                </div>
              </NavLink>

              <NavLink
                to="https://www.landvoice.com/help"
                className={"nav-text"}
              >
                <div className="nav-button">
                  {<FaIcons.FaQuestion />}
                  <span className={""}>{"Help"}</span>
                </div>
              </NavLink>
            </ul>

            <ul className=" flex gap-4 mb-4">
              <NavLink to="/accountsettings" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaCog />}
                  <span className={""}>{"Account settings"}</span>
                </div>
              </NavLink>

              <li className={"nav-text light-hover"}>
                <Link to="#" onClick={logout}>
                  <img src={logoutIcon} alt=""></img>
                  <span className={""}>{"Log Out"}</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default MobileView;
