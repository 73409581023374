import React from "react";
import { Close } from "@landvoice/icons";
import { removeParamFromString } from "./amenities";
import { StatusTypeEnum } from "../utils/selectionData";

// ActiveFilters component displays and manages active filters
const ActiveFilters = ({
  detailsOpen,
  filters,
  setIsUsingBatchId,
  setFilters,
  setLink,
  link,
  setLastRemovedFilter = () => {},
  expiredStatuses = [],
  setExpiredStatus = () => {},
}) => {
  const isBoolean = (value) => {
    return typeof value === "boolean";
  };
  console.log(expiredStatuses);

  function removeStatusByEnumValue(statusArray, enumObj, valueToRemove) {
    // Check if statusArray is an array
    if (!Array.isArray(statusArray)) {
      throw new TypeError(
        `Expected an array but received ${typeof statusArray}`
      );
    }

    // Check if valueToRemove is a string
    if (typeof valueToRemove !== "string") {
      throw new TypeError(
        `Expected a string but received ${typeof valueToRemove}`
      );
    }

    // Find the key in the enum that corresponds to the given value
    const keyToRemove = Object.keys(enumObj).find(
      (key) => enumObj[key] === valueToRemove
    );

    // If a corresponding key is found, remove it from the status array
    if (keyToRemove) {
      // Use filter to create a new array excluding the keyToRemove
      return statusArray.filter((status) => status !== keyToRemove);
    }

    // If no matching key is found, return the original status array
    return statusArray;
  }

  return (
    <div
      className={`${
        detailsOpen ? "" : "print:hidden"
      } min-h-[28px] w-full px-4 flex gap-3 mt-4 `}
    >
      {/* Header for Active Filters */}
      <p className="font-semibold w-fit whitespace-nowrap text-[14px] text-[#999999] leading-6">
        Active Filters:
      </p>
      <div className="flex gap-3 overflow-x-auto whitespace-nowrap hideScrollBar">
        {/* Map through filters array */}
        {filters.map((filter, index) => (
          <>
            {/* Map through each key-value pair in the filter object */}
            {Object.entries(filter).map(([key, value]) =>
              // Only display filter if it has a valid value
              value &&
              value !== "Any" &&
              value !== "All" &&
              value !== "SHow all" &&
              value !== "" &&
              value !== "searchDatType" &&
              value !== "file_upload_date" ? (
                <div
                  key={index}
                  className="flex items-center gap-2 justify-between px-2 h-[24px] w-fit   rounded-full bg-[#FED7C4] py-1 "
                >
                  {/* Display filter key and value */}
                  <p
                    key={key}
                    className="text-[11px]  text-[#666666] text-center font-bold leading-4"
                  >
                    {key === "Range" ? "" : key}
                    {isBoolean(value) || key === "Range" ? "" : ":"}
                    {value !== undefined && key === "batchId"
                      ? value?.split("=")[1]
                      : value !== undefined && key !== "batchId"
                      ? value
                      : "N/A"}
                    {key === "Beds" || key === "Baths" || key === "Sq Ft"
                      ? "+"
                      : ""}
                  </p>

                  {/* Close button to remove filter */}
                  {key !== "Status" &&
                    key !== "Search Date" &&
                    key !== "Range" &&
                    key !== "Distressed Level" && (
                      <div
                        className="opacity-50 hover:opacity-100"
                        onClick={() => {
                          // Handle batchId separately
                          if (key === "batchId") {
                            setIsUsingBatchId(false);
                          }

                          // Don't remove Status or Distressed Level filters
                          if (key !== "Status" && key !== "Distressed Level") {
                            // Remove the filter from the filters array
                            setFilters(
                              filters.filter((item) => item[key] !== value)
                            );
                            // send removed to filtersPage
                            setLastRemovedFilter(key);

                            // Update the link by removing the corresponding parameter
                            const newLink = removeParamFromString(
                              String(link),
                              key === "From"
                                ? "from_date"
                                : key === "to"
                                ? "To_date"
                                : key === "Spr Feet"
                                ? "sq_ft"
                                : key === "Postal Codes"
                                ? "PostalCode"
                                : key === "Min Price"
                                ? "min_price"
                                : key === "Max Price"
                                ? "max_price"
                                : key,
                              value
                            );

                            // Further clean up the link by removing any leftover parameters
                            setLink(
                              newLink?.replace(
                                `&${
                                  key === "From"
                                    ? "from_date"
                                    : key === "To"
                                    ? "to_date"
                                    : key === "Ppr Feet"
                                    ? "sq_ft"
                                    : key === "Postal Codes"
                                    ? "postalCode"
                                    : key === "Min Price"
                                    ? "min_price"
                                    : key === "Max Price"
                                    ? "max_price"
                                    : key
                                }=${encodeURIComponent(String(value))}`,
                                ""
                              )
                            );

                            setExpiredStatus(
                              removeStatusByEnumValue(
                                expiredStatuses,
                                StatusTypeEnum,
                                key
                              )
                            );
                          }
                        }}
                      >
                        <Close className={"text-[12px]"} />
                      </div>
                    )}
                </div>
              ) : null
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default ActiveFilters;
