import React from "react";
import { HDivider, SocialMedia } from "./amenities";
import capitalizeWords from "./capitalizeWords";
import { Mail } from "@landvoice/icons";

const OwnerContacts = ({ owner, onlySocial = !true }) => {
  return (
    <div className="lg:flex   flex-col h-full lg:py-6 py-3  md:w-[216px]">
      {!onlySocial && (
        <p className=" text-[14px] text-[#666666] lg:leading-6 font-semibold  lg:mb-4">
          Owner's Information
        </p>
      )}
      {!onlySocial && owner?.full_name && owner?.full_name !== " " ? (
        <p className=" text-[16px] text-[#666666] lg:leading-6 lg:mb-3 mb-1">
          {capitalizeWords(owner?.full_name || "--")}
        </p>
      ) : (
        !onlySocial && (
          <p className=" text-[16px] text-[#666666] leading-6 mb-3 italic">
            Owner's Name not found
          </p>
        )
      )}

      {!onlySocial && owner?.property?.street_address ? (
        <p className=" text-[14px] text-[#999999] leading-[14px] mb-[13px]">
          {owner?.property.street_address
            ? `${owner?.property?.street_address || "--"}, ${
                owner?.property?.city || "--"
              }, ${owner?.property?.state || "--"} ${
                owner?.property?.postal_code || "--"
              }`
            : "--"}
        </p>
      ) : (
        !onlySocial && (
          <p className=" text-[14px] text-[#999999] leading-[14px] mb-[13px] italic">
            No address found
          </p>
        )
      )}

      {!onlySocial && (
        <div className="flex gap-2 max-h-4 mb-2">
          <Mail />
          {!onlySocial && owner?.email ? (
            <p className=" leading-[14px] text-[14px] text-[#999999] underline cursor-pointer text-ellipsis overflow-hidden">
              <a href={`mailto:${owner?.email}`}>{owner?.email}</a>
            </p>
          ) : (
            !onlySocial && (
              <p className=" leading-[14px] text-[14px] text-[#999999] italic">
                No email found
              </p>
            )
          )}
        </div>
      )}
      {!onlySocial && <HDivider />}
      <div className="h-4 mt-2 flex gap-[14px]">
        {onlySocial && (
          <p className="font-semibold text-[12px] text-[#999999] leading-[14px]">
            Socials:
          </p>
        )}

        {SocialMedia(owner)}
      </div>
    </div>
  );
};

export default OwnerContacts;
