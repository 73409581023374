import React from "react";
import Modal from "../../common/Modal";
import { OutlineButtons } from "../../common/buttons";
import { NavLink } from "react-router-dom";

const ViewExpiredUploads = ({ handleOpen, open, data }) => {
  return (
    <Modal
      handleOpen={handleOpen}
      open={open}
      mxWidth={"max-w-[485px]"}
      title={"View Uploaded File"}
    >
      <div className=" overscroll-x-none max-h-[calc(70vh-32px)] overflow-y-scroll max-w-[485px]">
        <div className=" flex flex-col gap-12">
          <div>
            <div className="flex gap-4 h-7 items-center mb-6 ">
              <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                Upload Info{" "}
                <span className="font-[500] text-[12px] text-[#999999]"></span>
              </p>{" "}
              <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
            </div>
            <div className=" flex flex-col gap-4">
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">Batch Name:</p>
                <p className=" text-right">{data?.uploaded_file}</p>
              </div>
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">When Started:</p>
                <p className=" text-right">-</p>
              </div>
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">When Finished:</p>
                <p className=" text-right">{data?.complete_date}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex gap-4 h-7 items-center mb-6 ">
              <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                Total Records{" "}
                <span className="font-[500] text-[12px] text-[#999999]"></span>
              </p>{" "}
              <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
            </div>
            <div className=" flex flex-col gap-4">
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">Started:</p>
                <p className=" text-right">{data?.total_records}</p>
              </div>
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">Subscribed:</p>
                <p className=" text-right">{data?.subscribed_records}</p>
              </div>
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">Duplicates:</p>
                <p className=" text-right">-</p>
              </div>
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">Searched:</p>
                <p className=" text-right">{data?.searched_records}</p>
              </div>
              <div className=" flex justify-between text-[#666666] text-[14px] leading-5">
                <p className=" text-left font-semibold">With Results</p>
                <p className=" text-right">{data?.records_with_results}</p>
              </div>
            </div>
          </div>
          <div className=" w-[180px]">
            <NavLink to={`/expired?batch_id=${data.id}`}>
              <OutlineButtons label={"Open File"} />
            </NavLink>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewExpiredUploads;
